import { getInstance } from '@snapshot-labs/lock/plugins/vue3';
import { UPDATE_INTERVAL } from '@/helpers/constants';
import { defineStore } from 'pinia';
import { computed, reactive } from 'vue';
import { IStateType } from '@/store/modules/referral/models/referral-state';
import { ethersToJSBI } from '@/sdk/constants';
import { TokenAmount } from '@/sdk/entities/fractions/tokenAmount';
import { useStore } from 'vuex';
import { getMinterContract, transactionWithEstimatedGas } from '@/helpers/contract.helper';
import { safeDateNowPlusEstimatedMinutes } from '@/helpers/utils';
import { isZeroAddress } from '@/sdk/utils';
import { getMinterAddress } from '@/helpers/address.helper';
import { useEVMWallet } from '@/store/modules/wallet/useEVMWallet';
import { NETWORK_SUPPORTS_REFERRAL_PROGRAM } from '@/helpers/supporting-features-in-network';

export const useReferral = defineStore('referral', () => {
  const { state } = useStore();
  const { walletState } = useEVMWallet();
  const nativeToken = state.tokens.tokens.BLUES;
  const isRefProgram = computed(() => {
    return NETWORK_SUPPORTS_REFERRAL_PROGRAM ? !isZeroAddress(getMinterAddress()) : false;
  });

  const referralState = reactive<IStateType>({
    referralsCount: null,
    earnings: null,
    pendingEarnings: null,
    isReferrer: false,
    isReferral: false,
    referrerAddress: null,
    isStateReady: false,
    hasError: false,
  });

  const registerReferrer = async () => {
    const minterContract = getMinterContract(getInstance().web3.getSigner());

    await minterContract.registerReferrer();
  };

  const registerReferral = async refCode => {
    const minterContract = getMinterContract(getInstance().web3.getSigner());

    await transactionWithEstimatedGas(minterContract, 'registerReferral', [refCode]);
  };

  const getIsReferrer = async address => {
    const minterContract = getMinterContract();

    const isUserReferrer = await minterContract.isReferrer(address);

    return isUserReferrer;
  };

  const getReferralStatistics = async () => {
    const minterContract = getMinterContract();

    const referralStatistics = await minterContract.earnsFromReferralProgram(
      walletState.account,
      safeDateNowPlusEstimatedMinutes(0),
    );

    referralState.referralsCount = referralStatistics.referralsCnt.toNumber();
    referralState.earnings = new TokenAmount(nativeToken, ethersToJSBI(referralStatistics.earned));
    referralState.pendingEarnings = new TokenAmount(
      nativeToken,
      ethersToJSBI(referralStatistics.pendingReward),
    );
  };

  const checkIsReferral = referrerAddress => {
    referralState.isReferral = referrerAddress ? !!parseInt(referrerAddress, 16) : false;
  };

  const getUserReferrer = async () => {
    const minterContract = getMinterContract();

    const userReferrer = await minterContract.getUserReferrer(walletState.account);

    referralState.referrerAddress = userReferrer;
    checkIsReferral(userReferrer);
  };

  const updateReferralsInfo = async () => {
    if (!nativeToken) {
      console.warn(
        '[REFERRAL:UPDATE] Can not update referrals info, because native token is empty.',
      );
      return;
    }

    if (isRefProgram.value) {
      const isReferrer = await getIsReferrer(walletState.account);
      referralState.isReferrer = isReferrer;
      await getUserReferrer();
      await getReferralStatistics();
    }
    referralState.isStateReady = true;
  };

  updateReferralsInfo().then(() => (referralState.isStateReady = false));

  setInterval(updateReferralsInfo, UPDATE_INTERVAL);

  return {
    referralState,
    registerReferrer,
    getIsReferrer,
    registerReferral,
    getReferralStatistics,
    getUserReferrer,
    isRefProgram,
  };
});
