import { ChainId } from '@/sdk/constants';
import networks from '@/helpers/networks';
import { SupportedNetworkMode, SUPPORTED_NETWORK_MODE } from './SUPPORTED_NETWORK_MODE';
import { ENABLE_CROSS_CHAIN_NETWORK_IDS } from './ENABLE_CROSS_CHAIN_NETWORK_IDS';
import { ENABLE_FAKE_CARDANO_NETWORK } from '@/helpers/fakeCardanoNetwork';

type NetworksSupportingConfig = Record<SupportedNetworkMode, ChainId[]>;
type NetworkOptions = typeof networks[ChainId.MILKOMEDA_CARDANO_MAINNET];

export const CROSS_CHAIN_NETWORK_LIST_DROPDOWN_OPTIONS: NetworkOptions[] =
  ENABLE_CROSS_CHAIN_NETWORK_IDS.map(chainId => networks[chainId]);

export const NETWORKS_SUPPORTING_SINGLE_SIDE: readonly ChainId[] = Object.freeze(
  (
    {
      TESTNET: (() => {
        const chainIds: ChainId[] = [
          ChainId.MILKOMEDA_ALGORAND_TESTNET,
          ChainId.KAVA_TESTNET,
          ChainId.NEON_TESTNET,
        ];

        if (!ENABLE_FAKE_CARDANO_NETWORK) {
          chainIds.push(ChainId.MILKOMEDA_CARDANO_TESTNET);
        }

        return chainIds;
      })(),
      MAINNET: (() => {
        const chainIds: ChainId[] = [
          ChainId.MILKOMEDA_ALGORAND_MAINNET,
          ChainId.KAVA_MAINNET,
        ];

        if (!ENABLE_FAKE_CARDANO_NETWORK) {
          chainIds.push(ChainId.MILKOMEDA_CARDANO_MAINNET);
        }
        return chainIds;
      })(),
    } as NetworksSupportingConfig
  )[SUPPORTED_NETWORK_MODE],
);

export const NETWORKS_SUPPORTING_BRIDGE: readonly ChainId[] = Object.freeze(
  (
    {
      TESTNET: (() => {
        const chainIds: ChainId[] = [];

        if (!ENABLE_FAKE_CARDANO_NETWORK) {
          chainIds.push(ChainId.MILKOMEDA_CARDANO_TESTNET);
        }
        return chainIds;
      })(),
      MAINNET: (() => {
        const chainIds: ChainId[] = [];

        if (!ENABLE_FAKE_CARDANO_NETWORK) {
          chainIds.push(ChainId.MILKOMEDA_CARDANO_MAINNET);
        }
        return chainIds;
      })(),
    } as NetworksSupportingConfig
  )[SUPPORTED_NETWORK_MODE],
);

export const NETWORKS_SUPPORTING_FARMS: readonly ChainId[] = Object.freeze(
  (
    {
      TESTNET: [
        ChainId.MILKOMEDA_ALGORAND_TESTNET,
        ChainId.MILKOMEDA_CARDANO_TESTNET,
        ChainId.KAVA_TESTNET,
        ChainId.POLYGON_TESTNET,
        ChainId.NEON_TESTNET,
      ],
      MAINNET: [
        ChainId.MILKOMEDA_ALGORAND_MAINNET,
        ChainId.MILKOMEDA_CARDANO_MAINNET,
        ChainId.KAVA_MAINNET,
      ],
    } as NetworksSupportingConfig
  )[SUPPORTED_NETWORK_MODE],
);

export const NETWORKS_SUPPORTING_YIELD_POOLS: readonly ChainId[] = Object.freeze(
  (
    {
      TESTNET: [
        ChainId.MILKOMEDA_ALGORAND_TESTNET,
        ChainId.MILKOMEDA_CARDANO_TESTNET,
        ChainId.KAVA_TESTNET,
        ChainId.POLYGON_TESTNET,
        ChainId.NEON_TESTNET,
      ],
      MAINNET: [
        ChainId.MILKOMEDA_ALGORAND_MAINNET,
        ChainId.MILKOMEDA_CARDANO_MAINNET,
        ChainId.KAVA_MAINNET,
      ],
    } as NetworksSupportingConfig
  )[SUPPORTED_NETWORK_MODE],
);

export const NETWORKS_SUPPORTS_REFERRAL_PROGRAM: readonly ChainId[] = Object.freeze(
  (
    {
      TESTNET: (() => {
        const chainIds: ChainId[] = [
          ChainId.MILKOMEDA_ALGORAND_TESTNET,
          ChainId.KAVA_TESTNET,
          ChainId.POLYGON_TESTNET,
          ChainId.NEON_TESTNET,
        ];

        if (!ENABLE_FAKE_CARDANO_NETWORK) {
          chainIds.push(ChainId.MILKOMEDA_CARDANO_TESTNET);
        }
        return chainIds;
      })(),
      MAINNET: (() => {
        const chainIds: ChainId[] = [ChainId.MILKOMEDA_ALGORAND_MAINNET, ChainId.KAVA_MAINNET];

        if (!ENABLE_FAKE_CARDANO_NETWORK) {
          chainIds.push(ChainId.MILKOMEDA_CARDANO_MAINNET);
        }
        return chainIds;
      })(),
    } as NetworksSupportingConfig
  )[SUPPORTED_NETWORK_MODE],
);
