import { ChainId } from '@/sdk/constants';
import networks from '@/helpers/networks';
import { NetworkValue, WRAPPED_MILKOMEDA_CARDANO_NETWORK_NAME } from './NETWORK_NAMES';

type CrossSiteSettings = {
  prefix: string;
};
type Network = {
  name: string;
  value: NetworkValue;
  crossSite: CrossSiteSettings;
};

export const NETWORK_OPTIONS: Readonly<{
  testnet: Network[];
  mainnet: Network[];
}> = {
  testnet: [
    {
      name: WRAPPED_MILKOMEDA_CARDANO_NETWORK_NAME,
      value: 'Wrapped_Milkomeda_Cardano',
      crossSite: {
        prefix: '',
      },
    },
    {
      name: networks[ChainId.MILKOMEDA_CARDANO_TESTNET].name,
      value: 'Milkomeda_Cardano',
      crossSite: {
        prefix: 'MILKOMEDA_',
      },
    },
    {
      name: networks[ChainId.KAVA_TESTNET].name,
      value: 'Kava',
      crossSite: {
        prefix: '',
      },
    },
    {
      name: networks[ChainId.POLYGON_TESTNET].name,
      value: 'Polygon',
      crossSite: {
        prefix: '',
      },
    },
    {
      name: networks[ChainId.NEON_TESTNET].name,
      value: 'Neon',
      crossSite: {
        prefix: '',
      },
    },
  ],
  mainnet: [
    {
      name: WRAPPED_MILKOMEDA_CARDANO_NETWORK_NAME,
      value: 'Wrapped_Milkomeda_Cardano',
      crossSite: {
        prefix: '',
      },
    },
    {
      name: networks[ChainId.MILKOMEDA_CARDANO_MAINNET].name,
      value: 'Milkomeda_Cardano',
      crossSite: {
        prefix: 'MILKOMEDA_',
      },
    },
    {
      name: networks[ChainId.MILKOMEDA_ALGORAND_MAINNET].name,
      value: 'Milkomeda_Algorand',
      crossSite: {
        prefix: 'MILKOMEDA_',
      },
    },
    {
      name: networks[ChainId.KAVA_MAINNET].name,
      value: 'Kava',
      crossSite: {
        prefix: '',
      },
    },
    {
      name: networks[ChainId.POLYGON_MAINNET].name,
      value: 'Polygon',
      crossSite: {
        prefix: '',
      },
    },
  ],
};
