import { BigNumber } from '@ethersproject/bignumber';
import { PortfolioInfo } from '@/store/modules/portfolios/models/portfolio-info.interface';
import { PortfolioTokenInfo } from '@/store/modules/portfolios/models/portfolio-token-info.interface';
import { PortfolioDto } from '@/store/modules/portfolios/models/portfolio-dto.interface';
import { PortfolioTokenDto } from '@/store/modules/portfolios/models/portfolio-token-dto.interface';
import { Portfolio, TokenInfo } from '@/sdk/entities/portfolio';

export function flatPortfolioInfoAndConvertToPortfolioDto(
  portfolioInfo: PortfolioInfo,
): PortfolioDto[] {
  return portfolioInfo.contractAddress.map((_, index) => ({
    portfolioId: -1,
    name: portfolioInfo.name[index],
    contractAddress: portfolioInfo.contractAddress[index],
    baseTokenAddress: portfolioInfo.baseTokenAddress[index],
    lpTokenAddress: portfolioInfo.lpTokenAddress[index],
    lpTokenPrice: BigNumber.from(portfolioInfo.lpTokenPrice[index]),
    totalValue: BigNumber.from(portfolioInfo.totalValue[index]),
    tokenCount: BigNumber.from(portfolioInfo.tokenCount[index]),
    baseTokenPriceCoefficient: BigNumber.from(portfolioInfo.baseTokenPriceCoefficient[index]),
    tokens: flatPortfolioTokenInfoAndConvertToPortfolioTokenDto(portfolioInfo.tokens[index]),
  }));
}

function flatPortfolioTokenInfoAndConvertToPortfolioTokenDto(
  tokenInfo: PortfolioTokenInfo,
): PortfolioTokenDto[] {
  return tokenInfo.tokenAddress.map((_, index) => ({
    tokenAddress: tokenInfo.tokenAddress[index],
    amount: BigNumber.from(tokenInfo.amount[index]),
    price: BigNumber.from(tokenInfo.price[index]),
    depositLimit: BigNumber.from(tokenInfo.depositLimit[index]),
    withdrawLimit: BigNumber.from(tokenInfo.withdrawLimit[index]),
    depositEMAPrice: BigNumber.from(tokenInfo.depositEMAPrice[index]),
    withdrawEMAPrice: BigNumber.from(tokenInfo.withdrawEMAPrice[index]),
    portfolioShare: BigNumber.from(tokenInfo.portfolioShare[index]),
    targetWeight: BigNumber.from(tokenInfo.targetWeight[index]),
  }));
}

export function getPortfolioTokensWithFirstBaseToken(portfolio: Portfolio): TokenInfo[] {
  const tokens = [...portfolio.tokens, ...portfolio.crossChainTokens];
  const baseTokenIndex = tokens.findIndex(
    tokenInfo => portfolio.baseToken.unwrapWETH().symbol === tokenInfo.token.unwrapWETH().symbol,
  );
  tokens.unshift(tokens.splice(baseTokenIndex, 1)[0]);
  return tokens;
}
