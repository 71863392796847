import { ChainId } from '@/sdk/constants';

export default {
  poolFactory: {
    [ChainId.MILKOMEDA_CARDANO_MAINNET]: '0x6455b3FE3cB9815d71D296f0CFFEfc1d591A91f3',
    [ChainId.MILKOMEDA_CARDANO_TESTNET]: '0x6404d59Cd95652bE91607D66B9e57853E2EbF9A1',
    [ChainId.MILKOMEDA_ALGORAND_MAINNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.MILKOMEDA_ALGORAND_TESTNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.KAVA_TESTNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.KAVA_MAINNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.POLYGON_TESTNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.POLYGON_MAINNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.NEON_TESTNET]: '0x0000000000000000000000000000000000000000',
  },
  router: {
    [ChainId.MILKOMEDA_CARDANO_MAINNET]: '0x4a064418Ea8fd135433e366400850ad0D6497B45',
    [ChainId.MILKOMEDA_CARDANO_TESTNET]: '0x3100FfC6d2Ac652892d79bEC9fBb5a56eE9a1D06',
    [ChainId.MILKOMEDA_ALGORAND_MAINNET]: '0x993A63d254832d5BE38CA2F5eeff441606cC8442',
    [ChainId.MILKOMEDA_ALGORAND_TESTNET]: '0x3b4172E2B41009e638431B6e4bA6c8b789bD429D',
    [ChainId.KAVA_TESTNET]: '0x0C643aA4955d43D90F767aa965E4f7A81809bB94',
    [ChainId.KAVA_MAINNET]: '0x15A61376c4b89b064A829248713032c4F6D05F30',
    [ChainId.POLYGON_TESTNET]: '0x42fbd309C0A542223862841F83a7C22a5998f432',
    [ChainId.POLYGON_MAINNET]: '0xe03d836717b356911b41d53f21e64fb8de2a2a5a',
    [ChainId.NEON_TESTNET]: '0x7442d00cC5fe6C09B767aFf9c2EbC3E26A1DdDcF',
  },
  minter: {
    [ChainId.MILKOMEDA_CARDANO_MAINNET]: '0xdE6AB15d0786a0034B28Ed7e6B21ed95099CF48B',
    [ChainId.MILKOMEDA_CARDANO_TESTNET]: '0xC251D9F869D178Ce09E08fa830b35C0E850344F5',
    [ChainId.MILKOMEDA_ALGORAND_MAINNET]: '0x6455b3FE3cB9815d71D296f0CFFEfc1d591A91f3',
    [ChainId.MILKOMEDA_ALGORAND_TESTNET]: '0x18390a3db65e07AAC892DB1616C5b1d268FA0beF',
    [ChainId.KAVA_TESTNET]: '0xd65080B5D7686fA7D46Bd19cBEDB6B03FEd37e8c',
    [ChainId.KAVA_MAINNET]: '0x39F220f50f67BC6d7CeD35affba82191c43668b5',
    [ChainId.POLYGON_TESTNET]: '0xd585A1Be0db5e93A09fA090eECDA695dDe8ce25A',
    [ChainId.POLYGON_MAINNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.NEON_TESTNET]: '0x83E5F924ad457B05dC6bED5D5Ff6F7f5Ef11e475',
  },
  portfolioAndPairRegistry: {
    [ChainId.MILKOMEDA_CARDANO_MAINNET]: '0x8acc5cca055930b1206bdb8af1529464092ea644',
    [ChainId.MILKOMEDA_CARDANO_TESTNET]: '0xDC543851221aF52E2f86B93B6E7a64cCdaFfD482',
    [ChainId.MILKOMEDA_ALGORAND_MAINNET]: '0xa98C276d262Cc3Bf660189E2eBE74c4B8C18e50a',
    [ChainId.MILKOMEDA_ALGORAND_TESTNET]: '0x1C1Ac0E338bF1C8535d01A934977029D648145C0',
    [ChainId.KAVA_MAINNET]: '0x49399653f651A25924b3D8718276b5b4372577b1',
    [ChainId.KAVA_TESTNET]: '0x04702222EB8974C4686997f1671Beb0560330c7a',
    [ChainId.POLYGON_MAINNET]: '0x2080A319A4B11D097050722b6b65d09F754EdC83',
    [ChainId.POLYGON_TESTNET]: '0x0D8D46ee0287711beC44C2C66B3c7f78F8c1D3Db',
    [ChainId.NEON_TESTNET]: '0x2d1EF74f1776dCBf0fbEf3881Fb5371064306584',
  },
  multiCall: {
    [ChainId.MILKOMEDA_CARDANO_MAINNET]: '0xa46157Cda2D019Ba4cDcd8cE12A04760c15C355b',
    [ChainId.MILKOMEDA_CARDANO_TESTNET]: '0x0042c23B7A286cd0ed96C9FA31282057a061DB68',
    [ChainId.MILKOMEDA_ALGORAND_MAINNET]: '0x61EEE5a6c13c358101487f3b7c7Dd9863590C350',
    [ChainId.MILKOMEDA_ALGORAND_TESTNET]: '0x61EEE5a6c13c358101487f3b7c7Dd9863590C350',
    [ChainId.KAVA_TESTNET]: '0xfd5843DEa7209747df09767D0A2b9A6173e13901',
    [ChainId.KAVA_MAINNET]: '0xB16eddC4855B2A0D3482c83E0D1367b6137a3F4D',
    [ChainId.POLYGON_TESTNET]: '0xE518b5EBCFf61B2D6768A089515caeE47273c13b',
    [ChainId.POLYGON_MAINNET]: '0xF991039AB3590325629B5b09f9Ea35F4550ce01C',
    [ChainId.NEON_TESTNET]: '0x72C2A39401e6039B8Af220521B8FcdFCD3479553',
  },
  autoPool: {
    [ChainId.MILKOMEDA_CARDANO_MAINNET]: '0x8F141ed07b53D5483ed30b3E688F388fE3B412AF',
    [ChainId.MILKOMEDA_CARDANO_TESTNET]: '0x2611be40180C0ff803B97CCeD2112223ee535908',
    [ChainId.MILKOMEDA_ALGORAND_MAINNET]: '0xE0dFBce50f8266aA8A488dB4A5f2B40DD1f0FaeA',
    [ChainId.MILKOMEDA_ALGORAND_TESTNET]: '0xC2F160700148Ac7ada3Df86ac1c6174B53c066C6',
    [ChainId.KAVA_TESTNET]: '0x3A10930072519755027A99f82DB278f4E0FBe8CB',
    [ChainId.KAVA_MAINNET]: '0x8d7A76CB2cE26136b86f311e3422E939493608c9',
    [ChainId.POLYGON_TESTNET]: '0x26e7fC8cD03A00CCF08303F3A01b92aE462A2754',
    [ChainId.POLYGON_MAINNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.NEON_TESTNET]: '0x07f8c8796df7E63c204cb543B455D2127C9548B3',
  },
  liquidityDelegate: {
    [ChainId.MILKOMEDA_CARDANO_MAINNET]: '0xeE0dD0eC780944452c150f1ee0949F66D01F2E11',
    [ChainId.MILKOMEDA_CARDANO_TESTNET]: '0x33CEDa91aA07B0D328C837d0e847A04B1238a6fA',
    [ChainId.MILKOMEDA_ALGORAND_MAINNET]: '0xeE8e7F9Fe5512b8200BBa730107B19f64A576339',
    [ChainId.MILKOMEDA_ALGORAND_TESTNET]: '0xCFf6042c8C220D7E0CD935E73bDf4F7777Bd780B',
    [ChainId.KAVA_MAINNET]: '0x14Eb1F952A2f73ee664d063970a60732B02C9C03',
    [ChainId.KAVA_TESTNET]: '0xC1FF0a0029e8ad8E57fE76E2D03ED92556b116D4',
    [ChainId.POLYGON_MAINNET]: '0xF799001542B2B48aF1E316D1d3912dcc0937A4E1',
    [ChainId.POLYGON_TESTNET]: '0x5428A140b337D88834b2C9AE5c7FCa6b5Ac2B9ee',
  },
};
