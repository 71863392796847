import { ethers } from 'ethers';
import BigNumber from 'bignumber.js';

import { BLOCKS_PER_YEAR, DEFAULT_TOKEN_DECIMAL } from '@/sdk/constants';
import { BIG_TEN, ethersToBigNumber } from '@/utils/bigNumber';
import { StakingPool } from './stakingPool';
import { useReferral } from '@/store/modules/referral/useReferral';
import { APR_INCREASE_PERCENT } from '@/constants/REFERRAL_PARAMS';
import { useTokens } from '@/store/modules/tokens/useTokens';
import { DEFAULT_NETWORK_ID } from '@/helpers/networkParams.helper';
import { Token } from '@/sdk/entities/token';

const LOGGER = {
  groupCollapsed: (...label: any[]) => {
    if (isLoggingDisabled()) return;

    console.groupCollapsed(...label);
  },
  groupEnd: () => {
    if (isLoggingDisabled()) return;

    console.groupEnd();
  },
  log: (message?: any, ...optionalParams: any[]) => {
    if (isLoggingDisabled()) return;

    console.log(message, ...optionalParams);
  },
};

type StatusStakingType = {
  multiplier: ethers.BigNumber;
  rewardPerBlock: ethers.BigNumber;
  accDeposited: ethers.BigNumber;
  deposited: ethers.BigNumber;
  earned: ethers.BigNumber;
  totalReward: ethers.BigNumber;
  token: string;
  farm: string;
  tokenToMint: string;
  isSyrupPool: boolean;
};

export class Staking implements StakingPool {
  public readonly multiplier: BigNumber;
  public readonly rewardPerBlock: BigNumber;
  public readonly accDeposited: BigNumber;
  public readonly totalStaked: BigNumber;
  public readonly deposited: BigNumber;
  public readonly earned: BigNumber;
  public readonly totalReward: BigNumber;
  public readonly token: string;
  public readonly farm: string;
  public readonly nativeTokenPriceInBase: BigNumber;
  public readonly tokenToMint: string;
  public readonly isSyrupPool: boolean;

  constructor(
    statusStaking: StatusStakingType,
    nativeTokenPriceInBase: BigNumber,
    totalStaked?: ethers.BigNumber,
  ) {
    LOGGER.log('Staking.ts', statusStaking);
    this.multiplier = ethersToBigNumber(statusStaking.multiplier);
    this.rewardPerBlock = ethersToBigNumber(statusStaking.rewardPerBlock);
    this.accDeposited = ethersToBigNumber(statusStaking.accDeposited);
    this.totalStaked = ethersToBigNumber(totalStaked || statusStaking.accDeposited);
    this.deposited = ethersToBigNumber(statusStaking.deposited);
    this.earned = ethersToBigNumber(statusStaking.earned);
    this.totalReward = ethersToBigNumber(statusStaking.totalReward);
    this.token = statusStaking.token;
    this.farm = statusStaking.farm;
    this.nativeTokenPriceInBase = nativeTokenPriceInBase; // in USD
    this.tokenToMint = statusStaking.tokenToMint;
    this.isSyrupPool = statusStaking.isSyrupPool;
    LOGGER.log(
      'constructor',
      this.token,
      this.tokenToMint,
      totalStaked?.toString(),
      this.accDeposited.toString(),
      this.earned.toString(),
    );
  }

  public get getMintToken(): Token {
    const { getTokenByAddressAndChainId } = useTokens();
    return getTokenByAddressAndChainId(this.tokenToMint, +DEFAULT_NETWORK_ID!);
  }

  public get printApr(): BigNumber {
    let apr = this.rewardPerBlock
      .multipliedBy(BLOCKS_PER_YEAR)
      .dividedBy(this.accDeposited)
      .multipliedBy(100);
    const { referralState } = useReferral();
    apr =
      referralState.isReferral && !this.isSyrupPool
        ? apr.multipliedBy(1 + APR_INCREASE_PERCENT.REFERRAL / 100)
        : apr;

    LOGGER.log('apr', apr.valueOf());
    return isNaN(Number(apr.valueOf())) ? new BigNumber(0) : apr;
  }

  public get printApy(): BigNumber {
    return new BigNumber('1');
  }

  /**
   * Total stake in BLUES (native pool token)
   */
  public get printTotalStake(): BigNumber {
    return this.totalStaked.dividedBy(
      this.getMintToken ? BIG_TEN.pow(this.getMintToken.decimals) : DEFAULT_TOKEN_DECIMAL,
    );
  }

  public get printYourTotalReward(): BigNumber {
    return this.totalReward.dividedBy(
      this.getMintToken ? BIG_TEN.pow(this.getMintToken.decimals) : DEFAULT_TOKEN_DECIMAL,
    );
  }

  /**
   * Earn in BLUES (native pool token)
   */
  public get printYouEarned(): BigNumber {
    return this.earned.dividedBy(
      this.getMintToken ? BIG_TEN.pow(this.getMintToken.decimals) : DEFAULT_TOKEN_DECIMAL,
    );
  }

  public get printYouEarnedUSD(): BigNumber {
    return this.earned
      .dividedBy(
        this.getMintToken ? BIG_TEN.pow(this.getMintToken.decimals) : DEFAULT_TOKEN_DECIMAL,
      )
      .multipliedBy(this.nativeTokenPriceInBase);
  }

  public get printYouStaked(): BigNumber {
    return this.deposited.dividedBy(
      this.getMintToken ? BIG_TEN.pow(this.getMintToken.decimals) : DEFAULT_TOKEN_DECIMAL,
    );
  }

  public get printYouStakedUSD(): BigNumber {
    return this.deposited
      .dividedBy(
        this.getMintToken ? BIG_TEN.pow(this.getMintToken.decimals) : DEFAULT_TOKEN_DECIMAL,
      )
      .multipliedBy(this.nativeTokenPriceInBase);
  }

  public calculateUSD(value: string): BigNumber {
    return new BigNumber(value === '' ? 0 : value).multipliedBy(this.nativeTokenPriceInBase);
  }
}

// DEBUG

function isLoggingDisabled() {
  return !window['BLUESHIFT_DEBUG'].MANUAL_POOLS;
}
