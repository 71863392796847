export const REFERRAL_PROGRAM_LEARN_MORE =
  'https://blueshift-fi.gitbook.io/product-docs/product-concept/referral-program';
export const HIGH_RATES_LEARN_MORE =
  'https://blueshift-fi.gitbook.io/product-docs/product-concept/smart-minting-system';
export const AUTO_HODL_POOL_LEARN_MORE =
  'https://blueshift-fi.gitbook.io/product-docs/product-concept/yield-pools/auto-hodl-blues-pool';
export const SINGLE_SIDE_LEARN_MORE =
  'https://blueshift-fi.gitbook.io/product-docs/product-concept/portfolios/single-token-invest';
export const WSC_LEARN_MORE =
  'https://blueshift-fi.gitbook.io/product-docs/product-concept/cardano-wrapped-smart-contracts';

export const EXTERNAL_LINKS: { [key: string]: string } = {
  BLUESHIFT_ABOUT: 'https://blueshift.fi',
  BLUESHIFT_APP: 'https://app.blueshift.fi',
  WHITEPAPER: 'https://blueshift.fi/pdf/blueshift-whitepaper.pdf',
  CAREERS: 'https://angel.co/company/blueshiftfi',
  TELEGRAM_OFF_GROUP: 'https://t.me/blueshift',
  TELEGRAM_CHAT: 'https://t.me/BlueshiftGroup',
  GITBOOK: 'https://blueshift-fi.gitbook.io/product-docs/introduction/what-is-blueshift',
};

export const SOCIAL_LINKS: { [key: string]: string } = {
  TWITTER: 'https://twitter.com/blueshiftfi',
  TELEGRAM: 'https://t.me/BlueshiftGroup',
  MAIL: 'mailto:hello@blueshift.fi',
  GITHUB: 'https://github.com/blueshift-fi',
  DISCORD: 'https://t.co/zUNaFAd4o1',
};

export const REFERRAL_FAQ_LINKS: { [key: string]: string } = {
  CONNECT_WALLET:
    'https://blueshift-fi.gitbook.io/product-docs/getting-started/how-do-i-connect-wallet',
  GET_BASE_TOKEN_CARDANO:
    'https://blueshift-fi.gitbook.io/product-docs/getting-started/how-to-transfer-ada-from-cardano-to-milkomeda-network',
  GET_BASE_TOKEN_ALGORAND:
    'https://blueshift-fi.gitbook.io/product-docs/getting-started/how-to-transfer-algo-from-algorand-to-milkomeda-a1-network',
  GET_BASE_TOKEN_KAVA: 'https://www.kava.io/news/how-to-wrap-kava-to-wkava-a-kava-evm-tutorial',
  GET_BASE_TOKEN_POLIGON: 'https://faucet.polygon.technology/',
  ADD_LIQUIDITY:
    'https://blueshift-fi.gitbook.io/product-docs/getting-started/how-do-i-provide-liquidity-get-lp-tokens',
  WHAT_REWARD_FOR:
    'https://blueshift-fi.gitbook.io/product-docs/product-concept/smart-minting-system',
  STAKE_TO_FARM: 'https://blueshift-fi.gitbook.io/product-docs/getting-started/how-do-i-use-farms',
  PROGRAM_WORK: 'https://blueshift-fi.gitbook.io/product-docs/product-concept/referral-program',
  WHAT_IS_BLUESHIFT: 'https://blueshift-fi.gitbook.io/product-docs/introduction/what-is-blueshift',
  PORTFOLIOS_BENEFITS: 'https://blueshift-fi.gitbook.io/product-docs/product-concept/portfolios',
};

export const REFERRAL_SHARE_LINK = `https://${window.location.host}/#/ref?wallet=`;

export const ALGORAND_BRIDGE_MILKOMEDA = 'https://algorand-bridge.milkomeda.com/';

export const KAVA_GET_WKAVA = 'https://app.kava.io/evm/wkava';
export const POLIGON_GET_MATIC = 'https://faucet.polygon.technology/';

export const ANALYTICS_FRONTEND = {
  TESTNET: 'https://devinfo.blueshift.fi/',
  MAINNET: 'https://info.blueshift.fi/',
};

export const GITBOOK_LINKS = {
  PORTFOLIOS: 'https://blueshift-fi.gitbook.io/product-docs/product-concept/portfolios',
  SINGLE_SIDE:
    'https://blueshift-fi.gitbook.io/product-docs/product-concept/portfolios/single-token-invest',
  FARMING: 'https://blueshift-fi.gitbook.io/product-docs/product-concept/farming',
  STAKING: 'https://blueshift-fi.gitbook.io/product-docs/product-concept/yield-pools',
};
