export type NetworkValue =
  | 'Wrapped_Milkomeda_Cardano'
  | 'Milkomeda_Cardano'
  | 'Milkomeda_Algorand'
  | 'Kava'
  | 'Polygon'
  | 'Neon';

export const WRAPPED_MILKOMEDA_CARDANO_NETWORK_NAME: Readonly<string> = 'Cardano';
export const CARDANO_VIA_MILKOMEDA_NETWORK_NAME: Readonly<string> = 'Cardano via Milkomeda';
export const DEFAULT_NETWORK_VALUE: Readonly<NetworkValue> = 'Milkomeda_Cardano';
export const NETWORK_VALUE_BY_WRAPPED_NETWORK_VALUE: Readonly<Record<NetworkValue, NetworkValue>> =
  {
    Wrapped_Milkomeda_Cardano: 'Milkomeda_Cardano',
    Milkomeda_Cardano: 'Milkomeda_Cardano',
    Milkomeda_Algorand: 'Milkomeda_Algorand',
    Kava: 'Kava',
    Polygon: 'Polygon',
    Neon: 'Neon',
  };
