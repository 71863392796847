import injected from '@/helpers/connectors/snapshot-labs/injected';
import nufi from '@/helpers/connectors/snapshot-labs/nufi';
import walletconnect from '@snapshot-labs/lock/connectors/walletconnect';
import cryptosdk from '@/helpers/connectors/snapshot-labs/crypto-sdk';
import { DEFAULT_CHAIN_ID } from '@/constants/DEFAULT_CHAIN_ID';
import { DEFAULT_NETWORK_ID, NETWORK_ENGINE } from '@/helpers/networkParams.helper';
import fakeCardanoConnectors from '@/helpers/connectors/wallets/fake-cardano-connectors.json';
import { NETWORK_VALUE_BY_WRAPPED_NETWORK_VALUE } from '@/constants/NETWORK_NAMES';

const baseNetworkId = Number(DEFAULT_NETWORK_ID) ?? DEFAULT_CHAIN_ID;
const networkName = baseNetworkId === DEFAULT_CHAIN_ID ? 'mainnet' : 'testnet';
const defaultNetworkRPC = NETWORK_ENGINE;

export const CONNECTORS_LIST = {
  injected: {
    id: 'injected',
    name: 'MetaMask',
    icon: '/images/connectors/metamask-icon.svg',
    detect: ['isMobile', 'isBrowser'],
    disconnectNetworks: [],
  },
  'nufi-evm': {
    id: 'nufi-evm',
    name: 'NuFi',
    icon: '/images/connectors/nufi.svg',
    link: 'https://nu.fi/',
    detect: ['isMobile', 'isBrowser'],
    disconnectNetworks: [
      NETWORK_VALUE_BY_WRAPPED_NETWORK_VALUE.Milkomeda_Algorand,
      NETWORK_VALUE_BY_WRAPPED_NETWORK_VALUE.Kava,
    ],
  },
  walletconnect: {
    id: 'walletconnect',
    name: 'WalletConnect',
    network: networkName,
    chainId: baseNetworkId,
    icon: '/images/connectors/walletconnect.svg',
    options: {
      rpcUrl: defaultNetworkRPC,
      network: networkName,
      chainId: baseNetworkId,
      rpc: {
        [baseNetworkId]: defaultNetworkRPC,
      },
      qrcodeModalOptions: {
        mobileLinks: ['metamask'],
        desktopLinks: [],
      },
    },
    detect: ['isMobile', 'isBrowser'],
    disconnectNetworks: [],
  },
  flint: {
    ...fakeCardanoConnectors['flint'],
  },
  eternl: {
    ...fakeCardanoConnectors['eternl'],
  },
  typhon: {
    ...fakeCardanoConnectors['typhon'],
  },
  nufi: {
    ...fakeCardanoConnectors['nufi'],
  },
};

const options: any = { connectors: [] };
const lockConnectors = {
  injected,
  'nufi-evm': nufi,
  walletconnect,
  flint: cryptosdk,
  eternl: cryptosdk,
  typhon: cryptosdk,
  nufi: cryptosdk,
};

enum CONNECTOR_ENTRY {
  ID = 0,
  SETTINGS = 1,
}

Object.entries(CONNECTORS_LIST).forEach(connector => {
  options.connectors.push({
    key: connector[CONNECTOR_ENTRY.ID],
    connector: lockConnectors[connector[CONNECTOR_ENTRY.ID]],
    options: connector[CONNECTOR_ENTRY.SETTINGS]['options'] ?? connector[CONNECTOR_ENTRY.SETTINGS],
  });
});

export default options;
