import { isCardanoMainnetOrTestnet } from '@/store/modules/bridge/helpers/cardano-bridge.helper';
import BigNumber from 'bignumber.js';
import JSBI from 'jsbi';
import {
  BASE_SCAN_URLS,
  TOKEN_SCAN_URLS,
  BigintIsh,
  BignumberIsh,
  BN_ONE,
  BN_THREE,
  BN_TWO,
  BN_ZERO,
  ChainId,
  PRICE_MULTIPLIER,
  SOLIDITY_TYPE_MAXIMA,
  SolidityType,
  TOKEN_TRANSFERS_SCAN_URLS,
} from '@/sdk/constants';
import { USD_DECIMAL_PRECISION } from '@/constants/INTERFACE_SETTINGS';
import invariant from 'tiny-invariant';
import { getAddress } from '@ethersproject/address';
import warning from 'tiny-warning';
import { Percent } from '@/sdk/entities/fractions/percent';
import { APP_NETWORK_NAME, DEFAULT_NETWORK_ID } from '@/helpers/networkParams.helper';
import { useWallet } from '@/store/modules/wallet/useWallet';

export function isAddress(value: any): string | false {
  try {
    return getAddress(value);
  } catch {
    return false;
  }
}

export function validateSolidityTypeInstance(value: JSBI, solidityType: SolidityType): void {
  invariant(JSBI.greaterThanOrEqual(value, BN_ZERO), `${value} is not a ${solidityType}.`);
  invariant(
    JSBI.lessThanOrEqual(value, SOLIDITY_TYPE_MAXIMA[solidityType]),
    `${value} is not a ${solidityType}.`,
  );
}

export function validateUSDInput(value: string, isUSDOptionSelected: boolean): string {
  const BigNumValue = new BigNumber(value);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (isUSDOptionSelected && value && BigNumValue.decimalPlaces() > 2) {
    return BigNumValue.toFixed(USD_DECIMAL_PRECISION, BigNumber.ROUND_DOWN);
  }
  return value;
}

export function validateAndParseAddress(address: string): string {
  try {
    const checksummedAddress = getAddress(address);
    warning(address === checksummedAddress, `${address} is not checksummed.`);
    return checksummedAddress;
  } catch (error) {
    invariant(false, `${address} is not a valid address.`);
  }
}

export function parseBigintIsh(bigintIsh: BigintIsh): JSBI {
  return bigintIsh instanceof JSBI
    ? bigintIsh
    : typeof bigintIsh === 'bigint'
    ? JSBI.BigInt(bigintIsh.toString())
    : JSBI.BigInt(bigintIsh);
}

// converts a basis points value to a sdk percent
export function basisPointsToPercent(num: number): Percent {
  return new Percent(JSBI.BigInt(Math.floor(num)), JSBI.BigInt(10000));
}

export function sqrt(y: JSBI): JSBI {
  validateSolidityTypeInstance(y, SolidityType.uint256);
  let z: JSBI = BN_ZERO;
  let x: JSBI;
  if (JSBI.greaterThan(y, BN_THREE)) {
    z = y;
    x = JSBI.add(JSBI.divide(y, BN_TWO), BN_ONE);
    while (JSBI.lessThan(x, z)) {
      z = x;
      x = JSBI.divide(JSBI.add(JSBI.divide(y, x), x), BN_TWO);
    }
  } else if (JSBI.notEqual(y, BN_ZERO)) {
    z = BN_ONE;
  }
  return z;
}

export function sortedInsert<T>(
  items: T[],
  add: T,
  maxSize: number,
  comparator: (a: T, b: T) => number,
): T | null {
  invariant(maxSize > 0, 'MAX_SIZE_ZERO');
  // this is an invariant because the interface cannot return multiple removed items if items.length exceeds maxSize
  invariant(items.length <= maxSize, 'ITEMS_SIZE');

  // short circuit first item add
  if (items.length === 0) {
    items.push(add);
    return null;
  } else {
    const isFull = items.length === maxSize;
    // short circuit if full and the additional item does not come before the last item
    if (isFull && comparator(items[items.length - 1], add) <= 0) {
      return add;
    }

    let lo = 0,
      hi = items.length;

    while (lo < hi) {
      const mid = (lo + hi) >>> 1;
      if (comparator(items[mid], add) <= 0) {
        lo = mid + 1;
      } else {
        hi = mid;
      }
    }
    items.splice(lo, 0, add);
    return isFull ? items.pop()! : null;
  }
}

type ScanLinkType =
  | 'transaction'
  | 'token'
  | 'tokenTransfers'
  | 'address'
  | 'block'
  | 'countdown'
  | 'wallet';

// TODO: When all links defined then can merge this code with `switch` into `getScanLink`.
function getCardanoScanLink(data: string | number, type: ScanLinkType, chainId: number): string {
  switch (type) {
    case 'transaction': {
      return `${BASE_SCAN_URLS[chainId]}/transaction/${data}`;
    }
    case 'token': {
      return `${BASE_SCAN_URLS[chainId]}${TOKEN_SCAN_URLS[chainId]}${data}`;
    }
    case 'address':
    case 'wallet': {
      return `${BASE_SCAN_URLS[chainId]}/address/${data}`;
    }
    case 'tokenTransfers':
    case 'block':
    case 'countdown':
    default: {
      console.error(`[SCAN_LINK] ERROR : Cardano explorer link does not defined for ${type}.`);
      return '';
    }
  }
}

export function getScanLink(
  data: string | number,
  type: ScanLinkType,
  blockchainId?: ChainId,
): string {
  const { walletState } = useWallet();
  let chainId: number;

  if (blockchainId == undefined) {
    chainId = walletState.isInjected
      ? walletState.wallets[APP_NETWORK_NAME].chainId
      : DEFAULT_NETWORK_ID;
  } else {
    chainId = blockchainId;
  }

  if (isCardanoMainnetOrTestnet(chainId)) {
    return type === 'address'
      ? `${BASE_SCAN_URLS[DEFAULT_NETWORK_ID as unknown as ChainId]}/address/${data}`
      : getCardanoScanLink(data, type, chainId);
  }

  switch (type) {
    case 'transaction': {
      return `${BASE_SCAN_URLS[chainId]}/tx/${data}`;
    }
    case 'token': {
      return `${BASE_SCAN_URLS[chainId]}${TOKEN_SCAN_URLS[chainId]}${data}`;
    }
    case 'tokenTransfers': {
      return `${BASE_SCAN_URLS[chainId]}${TOKEN_SCAN_URLS[chainId]}${data}${TOKEN_TRANSFERS_SCAN_URLS[chainId]}`;
    }
    case 'block': {
      return `${BASE_SCAN_URLS[chainId]}/block/${data}`;
    }
    case 'countdown': {
      return `${BASE_SCAN_URLS[chainId]}/block/countdown/${data}`;
    }
    case 'wallet': {
      return `${BASE_SCAN_URLS[chainId]}/address/${data}/transactions`;
    }
    default: {
      return `${BASE_SCAN_URLS[chainId]}/address/${data}`;
    }
  }
}

export function toFixedWei(amountInWei: BigNumber) {
  return BigNumber(amountInWei.toFixed(0, BigNumber.ROUND_DOWN));
}

export function toWei(amount: BigNumber | string | number, decimals = 18): BigNumber {
  const multiplier = new BigNumber(10).pow(decimals);

  if (amount instanceof BigNumber) {
    return amount.multipliedBy(multiplier);
  }
  return new BigNumber(amount).multipliedBy(multiplier);
}

export function fromWei(amount: BigNumber | string | number, decimals = 18): BigNumber {
  if (decimals <= 0) {
    throw new Error(`Decimals must be a positive integer, but was ${decimals}`);
  }
  const divisor = new BigNumber(10).pow(decimals);

  if (amount instanceof BigNumber) {
    return amount.div(divisor);
  }
  return new BigNumber(amount).div(divisor);
}

export function compareTokenAddresses(address1: string, address2: string): boolean {
  return address1.toLowerCase() === address2.toLowerCase();
}

export function isZeroAddress(address: string): boolean {
  return address === '0x0000000000000000000000000000000000000000';
}

export function compareTokens(token1: { address: string }, token2: { address: string }): boolean {
  return compareTokenAddresses(token1.address, token2.address);
}

export function applySlippageInPercents(
  value: BigNumber | string,
  slippagePercent: number | string,
): BigNumber {
  if (!(value instanceof BigNumber)) {
    value = new BigNumber(value);
  }

  return (value as BigNumber).div(
    new BigNumber(1).plus(new BigNumber(slippagePercent).dividedBy(100)),
  );
}

/**
 * [USD R / R] returns price of one relative target token unit per relative USD stable token uint
 * @param weiWeiPriceWithMultiplier price of one target token wei per usd token weis multiplied by PRICE_MULTIPLIER to keep precision
 * @param decimalsUSD decimals of USD token
 * @param decimalsToken decimals of target token
 */
export function getPriceWithDecimals(
  weiWeiPriceWithMultiplier: BignumberIsh,
  decimalsUSD: number,
  decimalsToken: number,
): BigNumber {
  const bnWeiWeiPriceWithMultiplier =
    weiWeiPriceWithMultiplier instanceof BigNumber
      ? weiWeiPriceWithMultiplier
      : typeof weiWeiPriceWithMultiplier === 'bigint'
      ? new BigNumber(weiWeiPriceWithMultiplier.toString())
      : new BigNumber(weiWeiPriceWithMultiplier);

  const weiWeiPrice = bnWeiWeiPriceWithMultiplier.div(PRICE_MULTIPLIER);
  const priceTargetWeiForUSDRelative = weiWeiPrice.div(10 ** decimalsUSD);
  return priceTargetWeiForUSDRelative.multipliedBy(10 ** decimalsToken);
}
