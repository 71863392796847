import { useLocalStorage } from '@/composables/useLocalStorage';
import { SUPPORTED_NETWORK_MODE } from '@/constants/SUPPORTED_NETWORK_MODE';
import networks from '@/helpers/networks';
import { getURLParam } from '@/helpers/appParams.helper';
import { capitalizeFirstLetter } from '@/helpers/utils';
import { AddEthereumChainParameter } from '@/utils/blockchain';
import { ChainId } from '@/sdk/constants';
import {
  DEFAULT_NETWORK_VALUE,
  NETWORK_VALUE_BY_WRAPPED_NETWORK_VALUE,
  NetworkValue,
} from '@/constants/NETWORK_NAMES';
import { NETWORK_OPTIONS } from '@/constants/NETWORK_OPTIONS';
import { ANALYTICS_FRONTEND } from '@/constants/EXTERNAL_LINKS';

export const getNetworkId = netName => {
  return process.env['VUE_APP_' + netName.toUpperCase() + '_NETWORK_ID'];
};

export const getNetworkParams = (netId): AddEthereumChainParameter => {
  const net = networks[netId];
  const chainId = +net.key;
  const chainIdHec = '0x' + chainId.toString(16);

  return {
    chainId: chainIdHec,
    rpcUrls: net.rpc,
    chainName: net.shortName,
    nativeCurrency: net.nativeCurrency,
    blockExplorerUrls: [net.explorer],
    iconUrls: [net.iconLink],
  };
};

export const getNetworkURLParam = () => {
  const supportedNetOptions = NETWORK_OPTIONS[SUPPORTED_NETWORK_MODE.toLowerCase()];
  const supportedNetsShortNames = supportedNetOptions.map(item => item.value);
  const netURLParam = capitalizeFirstLetter(getURLParam('network'));
  const isURLNetSupported = supportedNetsShortNames.includes(netURLParam);

  return isURLNetSupported ? netURLParam : '';
};

/**
 * Network value gets from 'network' URL param, local storage or default value.
 * Network which user select for app.
 */
export const APP_NETWORK_NAME: NetworkValue = String(
  getNetworkURLParam() ||
    useLocalStorage().getItem<string>(`network_${SUPPORTED_NETWORK_MODE}`) ||
    DEFAULT_NETWORK_VALUE,
) as NetworkValue;
/**
 * Unwrapped network value.
 */
export const SELECTED_NETWORK_NAME = NETWORK_VALUE_BY_WRAPPED_NETWORK_VALUE[APP_NETWORK_NAME];

export const CROSS_SITE_NETWORK_PREFIXES = {};
NETWORK_OPTIONS[SUPPORTED_NETWORK_MODE.toLowerCase()].forEach(option => {
  CROSS_SITE_NETWORK_PREFIXES[option.value.toUpperCase()] = option.crossSite.prefix;
});

// FIX:
// When localstorage contains incorrect network: remove item and reload page.
if (APP_NETWORK_NAME && !SELECTED_NETWORK_NAME) {
  useLocalStorage().removeItem(`network_${SUPPORTED_NETWORK_MODE}`);
  window.location.reload();
}
export const DEFAULT_NETWORK_ID = getNetworkId(SELECTED_NETWORK_NAME);
if (!DEFAULT_NETWORK_ID) {
  console.error(
    '[ERROR] BLUESHIFT App can not launched.',
    ' URL or Local Storage contains incorrect data. Clean up URL and Local Storage.',
  );
}

export const FULL_SELECTED_NETWORK_NAME = networks[Number(DEFAULT_NETWORK_ID)].name;

export const SUPPORTED_NETWORK_IDS =
  process.env['VUE_APP_' + SELECTED_NETWORK_NAME.toUpperCase() + '_SUPPORTED_NETWORK_IDS'];

export const NETWORK_BACKEND_URL =
  process.env['VUE_APP_' + SELECTED_NETWORK_NAME.toUpperCase() + '_BACKEND_URL'];

export const NETWORK_ENGINE =
  process.env['VUE_APP_' + SELECTED_NETWORK_NAME.toUpperCase() + '_ENGINE_NETWORK'];

export const NETWORK_USD_TOKEN_ADDRESS =
  process.env['VUE_APP_' + SELECTED_NETWORK_NAME.toUpperCase() + '_USD_TOKEN_ADDRESS'];

export const BLUES_CROSSCHAIN_URL = process.env.VUE_APP_BLUES_CROSSCHAIN_URL;

export const BLUES_CROSSCHAIN_TRANSACTION_STATUS_RETRY_DELAY_IN_SEC =
  Number(process.env.VUE_APP_BLUES_CROSSCHAIN_TRANSACTION_STATUS_RETRY_DELAY_IN_SEC) || 5;

export const ANALYTIC_BACKEND_URL = process.env.VUE_APP_ANALYTIC_BACKEND_URL;

export const ANALYTIC_FRONTEND_URL = `${ANALYTICS_FRONTEND[SUPPORTED_NETWORK_MODE]}#/`;

export const getChainNameById = (id: ChainId): string => {
  const chainName = networks[id]?.name;
  if (!chainName) {
    throw new Error(`Blockchain ${id} was not found.`);
  }
  return chainName;
};
