import { computed, reactive } from 'vue';
import { defineStore } from 'pinia';
import { Provider, Web3Provider } from '@ethersproject/providers';
import { getInstance } from '@snapshot-labs/lock/plugins/vue3';
import { reloadPage } from '@/utils/page';
import { WalletData, WalletState } from '@/store/modules/wallet/models/wallet.state';
import {
  APP_NETWORK_NAME,
  SELECTED_NETWORK_NAME,
  getNetworkParams,
} from '@/helpers/networkParams.helper';
import { SUPPORTED_NETWORK_IDS } from '@/utils/blockchain';
import { useConnector } from './connector/useConnector';
import { CustomLockInstance } from './connector/models/lock-instance.interface';
import networks from '@/helpers/networks';
import { useEVMWallet } from './useEVMWallet';
import { ENABLE_FAKE_CARDANO_NETWORK } from '@/helpers/fakeCardanoNetwork';
import { CardanoWallet, MilkomedaProvider } from 'crypto-sdk';
import { NetworkValue } from '@/constants/NETWORK_NAMES';
import fakeCardanoConnectors from '@/helpers/connectors/wallets/fake-cardano-connectors.json';
import { CONNECTORS_LIST } from '@/utils/auth';

const WALLET_LOG = 'WALLET';

export const useWallet = defineStore('wallet', () => {
  const {
    walletState: evmWalletState,
    initWeb3Engine: evmWalletInitWeb3Engine,
    login: evmWalletLogin,
    disconnect: evmWalletLogout,
    getWeb3Engine: getEVMWeb3Engine,
  } = useEVMWallet();
  const { connectorState, initConnector } = useConnector();

  let providers = {};
  let web3Engine: Provider | null = null;

  const walletState = reactive<WalletState>({
    type: ENABLE_FAKE_CARDANO_NETWORK ? 'SEVERAL_CHAIN' : 'EVM',
    authLoading: false,
    isInjected: false,
    connectedNetworks: [],
    wallets: {},
  });

  const getWeb3Engine = () => web3Engine;

  const getProviderBy = (networkValue: NetworkValue) => {
    return providers[networkValue];
  };

  // GETTERS
  const getWalletName = computed(() => {
    return walletState.wallets[APP_NETWORK_NAME]?.account ?? null;
  });

  const getWalletNetwork = computed(() => {
    return walletState.wallets[APP_NETWORK_NAME]
      ? networks[walletState.wallets[APP_NETWORK_NAME].chainId]
      : null;
  });

  const getSupportedNetworksNames = computed(() => {
    const supportedNetworks = SUPPORTED_NETWORK_IDS!.map(networkId => {
      return networks[networkId]?.name || 'not defined';
    });
    return supportedNetworks.join(', ');
  });

  const getFirstSupportedNetwork = computed(() => {
    return getNetworkParams(SUPPORTED_NETWORK_IDS![0]);
  });

  function resetConnectionState() {
    walletState.isInjected = false;
    walletState.connectedNetworks = [];
    providers = {};
    walletState.wallets = {};
  }

  function setEvmWalletTo(network: NetworkValue) {
    const evmWallet: WalletData = {
      account: evmWalletState.account!,
      chainId: evmWalletState.network!.chainId,
      connector: evmWalletState.connector!,
    };
    walletState.wallets[network] = evmWallet;
  }

  async function setWalletsWhenFakeCardanoNetworks() {
    setEvmWalletTo(SELECTED_NETWORK_NAME);
    const cardanoProvider = getProviderBy(APP_NETWORK_NAME);
    const [networkId, account] = await Promise.all([
      cardanoProvider.getNetworkId(),
      cardanoProvider.getAddress(),
    ]);
    const cardanoWallet: WalletData = {
      account: account,
      chainId: networkId,
      connector: evmWalletState.connector!,
    };
    walletState.wallets[APP_NETWORK_NAME] = cardanoWallet;
  }

  // ACTIONS
  async function initWeb3Engine() {
    await evmWalletInitWeb3Engine();
    web3Engine = getEVMWeb3Engine();
  }

  async function login(connector = 'injected') {
    walletState.authLoading = true;

    try {
      await evmWalletLogin(connector);
      walletState.isInjected = true;
      walletState.isNetworkSupported = evmWalletState.isNetworkSupported ?? null;

      const auth: CustomLockInstance = getInstance();
      if (ENABLE_FAKE_CARDANO_NETWORK && Object.keys(fakeCardanoConnectors).includes(connector)) {
        const milkomedaProvider: MilkomedaProvider = auth.provider.value;
        const cardanoProvider: CardanoWallet = milkomedaProvider.cardanoProvider;
        walletState.connectedNetworks = [APP_NETWORK_NAME, SELECTED_NETWORK_NAME];
        providers[APP_NETWORK_NAME] = cardanoProvider;
        providers[SELECTED_NETWORK_NAME] = new Web3Provider(milkomedaProvider);

        await setWalletsWhenFakeCardanoNetworks();
      } else {
        walletState.connectedNetworks = [APP_NETWORK_NAME];
        providers[APP_NETWORK_NAME] = new Web3Provider(auth.provider.value);

        setEvmWalletTo(APP_NETWORK_NAME);
      }
    } catch (error) {
      walletState.isInjected = false;
      console.error(`[${WALLET_LOG}:LOGIN] ERROR : `, error);

      throw error;
    } finally {
      walletState.authLoading = false;
    }

    console.log(`[${WALLET_LOG}:LOGIN] State : `, walletState);
  }

  async function connect() {
    console.log('CONNECT!!!!');
    await initConnector();
    if (connectorState.connector) {
      if (
        (ENABLE_FAKE_CARDANO_NETWORK &&
          !Object.keys(fakeCardanoConnectors).includes(connectorState.connector)) ||
        CONNECTORS_LIST[connectorState.connector].disconnectNetworks.includes(SELECTED_NETWORK_NAME)
      ) {
        await disconnect();
        return;
      }
      await login(connectorState.connector);
    }
  }

  async function disconnect() {
    resetConnectionState();
    await evmWalletLogout();
    reloadPage();
  }

  return {
    walletState,
    getWeb3Engine,
    getProviderBy,
    // actions
    initWeb3Engine,
    connect,
    disconnect,
    login,
    // getters
    getWalletName,
    getWalletNetwork,
    getSupportedNetworksNames,
    getFirstSupportedNetwork,
  };
});
