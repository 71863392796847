<template>
  <router-view />
</template>

<script>
import { mapActions } from 'vuex';
import { MODULE_NAMES } from '@/store';
import { TOKENS_ACTION_TYPES } from '@/store/modules/tokens/tokens.module';
import { ROUTES_ACTION_TYPES } from '@/store/modules/routes/routes.module';
import { LAYOUT_ACTION_TYPES } from '@/store/modules/layout/layout.module';
import { PORTFOLIO_ACTION_TYPES } from '@/store/modules/portfolios/portfolios.module';
import { DEFAULT_NETWORK_ID, SELECTED_NETWORK_NAME } from '@/helpers/networkParams.helper';
import { loadTokens, useTokens } from '@/store/modules/tokens/useTokens';
import { useBalances } from '@/store/modules/tokens/useBalances';
import { useWallet } from '@/store/modules/wallet/useWallet';
import { getLockConnectorName, lookForProviderBy } from '@/utils/blockchain';
import { useLayout } from '@/store/modules/layout/useLayout';
import { HardwareWalletIsNotSupportedError } from '@/sdk/errors';

export default {
  name: 'Liquifi',
  setup() {
    const { walletState, initWeb3Engine, connect } = useWallet();
    const { initTokenBalances, updateTokenBalances } = useBalances();
    const { setTheme } = useLayout();

    return {
      walletState,
      initEngine: initWeb3Engine,
      walletInit: connect,
      initTokenBalances,
      updateTokenBalances,
      setTheme,
    };
  },
  watch: {
    'walletState.isInjected': {
      handler(isWalletConnected) {
        if (isWalletConnected && !this.firstInitialization) {
          this.initApp();
        }
      },
    },
  },
  data() {
    return {
      firstInitialization: true,
    };
  },
  computed: {
    isNetworkSupported() {
      return this.walletState.isNetworkSupported;
    },
  },
  methods: {
    ...mapActions(MODULE_NAMES.TOKENS, {
      loadTokensLists: TOKENS_ACTION_TYPES.LOAD_TOKENS_LISTS,
      loadTokensByNetworkId: TOKENS_ACTION_TYPES.LOAD_TOKENS_FROM_SELECTED_LIST_BY_NETWORK_ID,
      initTokenPrices: TOKENS_ACTION_TYPES.INIT_TOKEN_PRICES,
      updateTokenPrices: TOKENS_ACTION_TYPES.UPDATE_TOKEN_PRICES,
      createAllTokensList: TOKENS_ACTION_TYPES.LOAD_TOKENS_FROM_ALL_NETWORKS,
    }),
    ...mapActions(MODULE_NAMES.ROUTES, {
      getPairs: ROUTES_ACTION_TYPES.GET_PAIRS,
      getPairsWithBalances: ROUTES_ACTION_TYPES.GET_PAIRS_WITH_BALANCES,
    }),
    ...mapActions(MODULE_NAMES.LAYOUT, {
      setLoadingUi: LAYOUT_ACTION_TYPES.SET_LOADING_UI,
    }),
    ...mapActions(MODULE_NAMES.PORTFOLIOS, {
      initPortfolios: PORTFOLIO_ACTION_TYPES.INIT_PORTFOLIOS,
      getPortfoliosStatistics: PORTFOLIO_ACTION_TYPES.GET_PORTFOLIO_STATISTICS,
    }),
    async initWallet() {
      try {
        if (!this.walletState.isInjected) {
          await Promise.all([this.initEngine(), this.loadTokensLists()]);
          await this.walletInit();
        }
      } catch (error) {
        if (!(error instanceof HardwareWalletIsNotSupportedError)) {
          console.error(`[APP:WALLET:INIT] ERROR : `, error);
        }
      }

      return this.walletState.isInjected;
    },
    async getInitialisationParameters(isWalletConnected) {
      let chainId = Number(DEFAULT_NETWORK_ID);
      let loadFullApp = false;
      if (isWalletConnected && this.isNetworkSupported) {
        chainId = Number(this.walletState.wallets[SELECTED_NETWORK_NAME].chainId);
        loadFullApp = true;
      }

      return { chainId, loadFullApp };
    },
    async loadTokensAndPairs(chainId, loadFullApp) {
      await this.createAllTokensList();
      await this.loadTokensByNetworkId(chainId);
      const requests = await Promise.all([
        this.getPairs(),
        this.initTokenBalances(),
        this.initTokenPrices(),
        this.updateTokenBalances(),
      ]);

      if (loadFullApp) {
        const pairs = requests[0];
        await this.getPairsWithBalances(pairs);
      }
    },
    async initApp() {
      this.setLoadingUi(true);

      //TODO put to another place
      await loadTokens();
      const { getTokensListByChainId } = useTokens();
      console.log(
        '[APP:INIT] Tokens list by chainId : ',
        getTokensListByChainId(DEFAULT_NETWORK_ID),
      );
      const isWalletConnected = await this.initWallet();
      if (isWalletConnected && !this.isNetworkSupported) {
        this.setLoadingUi(false);
        return;
      }

      const { chainId, loadFullApp } = await this.getInitialisationParameters(isWalletConnected);

      await this.loadTokensAndPairs(chainId, loadFullApp);

      await this.initPortfolios();
      this.setLoadingUi(false);
    },
  },
  async created() {
    this.setTheme();
    window.addEventListener('load', async () => {
      const provider = lookForProviderBy(getLockConnectorName());
      if (provider) {
        try {
          await provider.request({ method: 'eth_requestAccounts' });
        } catch (e) {
          console.error(`evm provider enabling error: ${e.message}`);
        }
      }
      await this.initApp();
      this.firstInitialization = false;
    });
  },
};
</script>

<style lang="scss">
#app {
  min-height: 100vh;
  max-width: 100vw;
}
</style>
